import React, { useState, useEffect, useCallback } from 'react';
import './ProductDashboard.css'; // Estilos importados
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Importa o componente de ícones
import { faEdit } from '@fortawesome/free-solid-svg-icons'; // Importa o ícone de edição

const ProductDashboard = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newCost, setNewCost] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const token = process.env.REACT_APP_API_TOKEN;
  const itemsPerPage = 16;

  // Fetch products from API with optional searchTerm
  const fetchProducts = useCallback(async (searchTerm = '') => {
    setIsLoading(true);
    if (currentPage === 1) {
      setProducts([]); // Limpar os produtos existentes
    }
    try {
      const url = searchTerm
        ? `/atoomic_api/produtos?page=${currentPage}&limit=${itemsPerPage}&searchTerm=${encodeURIComponent(searchTerm)}`
        : `/atoomic_api/produtos?page=${currentPage}&limit=${itemsPerPage}`;
      
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const data = await response.json();
      setProducts(prevProducts => currentPage === 1 ? data.items : [...prevProducts, ...data.items]); // Reset on new search
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, token, itemsPerPage]);

  // Load products on initial render and when the page or searchTerm changes
  useEffect(() => {
    fetchProducts(searchTerm);
  }, [currentPage, searchTerm, fetchProducts]);

  // Update the cost of the selected product
  const updateCost = async () => {
    if (!selectedProduct || !newCost) return;
  
    try {
      const response = await fetch(`/atoomic_api/produtos/${selectedProduct[3]}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ custo: parseFloat(newCost) })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const updatedProduct = await response.json();
  
      setProducts(prevProducts => 
        prevProducts.map(p => 
          p[0] === selectedProduct[3] ? { ...p, 4: updatedProduct.custo } : p
        )
      );
      setSelectedProduct(null);
      setNewCost('');
  
      // Recarregar os dados para garantir sincronização
      await fetchProducts(searchTerm);
    } catch (error) {
      console.error('Error updating cost:', error);
      alert('Falha ao atualizar o custo. Por favor, tente novamente.');
    }
  };

  // Infinite Scroll functionality
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !isLoading && currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  return (
    <div className="container-produtos">
      <h1 className="title">Produtos</h1>

      {/* Filtro de busca */}
      <div className="search-container">
        <input
          type="text"
          id="searchInput"
          placeholder="Pesquisar produtos..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value); 
            setCurrentPage(1); // Reset page when new search is done
          }}
          className="search-input"
        />
      </div>
      
      {/* Tabela de produtos com scroll infinito */}
      <div className="table-container" onScroll={handleScroll}>
        <table className="table">
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Plataforma</th>
              <th>Grupo</th>
              <th>Produto</th>
              <th>Custo</th>
              <th>Qtd Vendas</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((product) => (
                <tr key={product[3]}>
                  <td>{product[0]}</td>
                  <td>{product[1]}</td>
                  <td>{product[2]}</td>
                  <td>{product[3]}</td>
                  <td>{product[4] ? parseFloat(product[4]).toFixed(2) : '0.00'}</td>
                  <td>{product[5] ? parseFloat(product[5]).toFixed(0) : '0'}</td>
                  <td>
                    <span className="edit-icon" onClick={() => setSelectedProduct(product)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: 'center' }}>Nenhum produto encontrado</td>
              </tr>
            )}
          </tbody>
        </table>

        {isLoading && <div className="loading">Carregando mais produtos...</div>}
      </div>

      {/* Modal de Edição */}
      {selectedProduct && (
        <div id="editModal" className="modal" style={{ display: 'block' }}>
          <div className="modal-content">
            <span className="close" onClick={() => setSelectedProduct(null)}>&times;</span>
            <form onSubmit={(e) => { e.preventDefault(); updateCost(); }}>
              <input type="hidden" id="editProductCode" value={selectedProduct[3]} />
              <label>Empresa:</label>
              <input type="text" value={selectedProduct[0]} disabled />
              <label>Plataforma:</label>
              <input type="text" value={selectedProduct[1]} disabled />
              <label>Grupo:</label>
              <input type="text" value={selectedProduct[2]} disabled />
              <label>Produto:</label>
              <input type="text" value={selectedProduct[3]} disabled />
              <label>Custo:</label>
              <input
                type="number"
                step="0.01"
                value={newCost}
                onChange={(e) => setNewCost(e.target.value)}
                required
              />
              <button type="submit" className="button">Atualizar Custo</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDashboard;
